import { useTranslation } from 'hooks/useTranslation';
import ExternalFunnel from './ExternalFunnel';
import type { FC } from 'react';
import type { ExternalFunnelProps } from './ExternalFunnel';

type Props = ExternalFunnelProps & {
  renderButton: (openFunnel: () => void) => JSX.Element;
};

const ExternalFunnelButton: FC<Props> = ({
  funnelId,
  wrapperClass,
  funnelClass,
  containerClasses,
  useShadow,
  customFieldOverrides,
  defaultCountryCodeOverride,
  showHeadline,
  roundedCorner,
  config,
  onInteraction,
  renderButton,
  themeOverride,
}) => {
  const { locale } = useTranslation();

  return (
    <>
      {renderButton(() => {
        void window?.openExternalFunnel({
          funnelId,
          locale,
          wrapperClass,
          funnelClass,
          containerClasses,
          useShadow,
          customFieldOverrides,
          defaultCountryCodeOverride,
          showHeadline,
          roundedCorner,
          config,
          onInteraction,
          themeOverride,
        });
      })}
      <ExternalFunnel funnelId={funnelId} />
    </>
  );
};

export default ExternalFunnelButton;
